import React from "react";
import Demo from "./Demo";
import { Link } from "gatsby";

let playingVideo = {};

export const DemoGroup = function ({ videos }) {
  if (videos) {
    videos.map(function (video, index) {
      playingVideo[index] = video.playing;
      return true;
    });
  }

  return (
    <React.Fragment>
      {videos && (
        <div className="container">
          <div className="row justify-content-center mt-lg-4">
            <div className="col-lg-10 text-center px-5 px-lg-0">
              {videos?.map(function (video, index) {
                return (
                  <React.Fragment key={`video-` + index}>
                    {video.button && (
                      <Link
                        to={`#video-` + index}
                        className="btn btn-sm btn-outline-secondary mx-1 mb-2"
                      >
                        <span className="far fa-play mr-2"></span>{video.button}
                      </Link>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {videos &&
        videos.map(function (video, index) {
          return (
            <Demo
              key={`demovideo-` + index}
              tooltips={video.captions}
              title={video.title}
              intro={video.intro}
              video={video.video}
              ctatext={video.cta_1_text}
              ctalink={video.cta_1_link}
              bg={`bg-` + video.background}
              rightside={video.rightside}
              playingVideo={playingVideo}
              id={index}
            />
          );
        })}
    </React.Fragment>
  );
};
export default DemoGroup;
