import React from "react";
import Image from "../Hacks/Image";

export const ImageModule = ({
  mobile,
  desktop,
  full_width = true,
  background = "white",
  alt = "",
}) => {
  return (
    <section className={`image bg-${background} m-0`}>
      <div className={full_width ? "container-fluid p-0" : "container"}>
        <div className="news-inner row justify-content-center contained-xl">
          <div className={`col-9 ${full_width ? "col-lg-10" : "col-lg-8"}`}>
            <Image
              image={desktop}
              className="d-none d-lg-block w-100"
              alt={alt}
            />
            <Image image={mobile} className="d-lg-none w-100" alt={alt} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageModule;
