import React from "react";
import { Link } from "gatsby";
// import Image from "../Hacks/Image";

export const HomeSuccess = ({
  title,
  linked_success_story,
  more_link_title,
}) => {
  return (
    <section id="success">
      <div className="container-fluid bg-light">
        <div className="row contained-xl justify-content-center">
          <div className="col-lg-10">
            <h3 className="h2 d-block d-md-none" data-aos="fade-in">
              {title}
            </h3>
            <div className="row justify-content-center">
              <div className="col-md-5 pb-md-5 mb-3 mb-md-5" data-aos="fade-up">
                {/* <Image
                  url={
                    linked_success_story.featured_image.url.localFile.url
                  }
                  localFile={
                    linked_success_story.featured_image.url.localFile
                  }
                  classes="w-100 pb-5 shadow-lg"
                  alt=""
                /> */}
              </div>
              <div className="col-md-6 col-lg-5 col-xl-4 offset-lg-1 mb-5 mb-md-0 align-self-center">
                <h3 className="h2 d-none d-md-block" data-aos="fade-in">
                  {title}
                </h3>
                <h4 className="h4 text-secondary" data-aos="fade-in">
                  {linked_success_story.post_title}
                </h4>
                <p data-aos="fade-in">{linked_success_story.excerpt}</p>
                <Link
                  cover
                  direction="right"
                  bg="#1F1A3C"
                  className="btn d-block d-lg-inline-block mb-3 btn-primary mr-lg-3"
                  to={linked_success_story.permalink}
                  data-aos="fade-in"
                >
                  Read about {linked_success_story.post_title}
                </Link>
                <Link
                  cover
                  direction="right"
                  bg="#1F1A3C"
                  className="btn d-block d-lg-inline-block mb-3 btn-outline-primary"
                  data-aos="fade-in"
                  to="/success/"
                >
                  {more_link_title}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSuccess;
