import React from "react";

import HeroIntro from "./General/HeroIntro";
import PodcastHeader from "./General/PodcastHeader";

import HomeHero from "./Home/HomeHero";
import LearnMore from "./General/LearnMore";
import Newsletter from "./General/Newsletter";
import IFrameEmbed from "./General/IFrameEmbed";
import LogoLockup from "./General/LogoLockup";
import Features from "./General/Features2";
import Stats from "./General/Stats";
import FAQBlock from "./General/FaqBlock";
import SimpleCTA from "./General/SimpleCTA";
import GeneralContent from "./General/GeneralContent";
import ImageText from "./General/ImageText";
import LargeQuote from "./General/LargeQuote";
import Testimonials from "./General/Testimonials";
import PricingTable from "./Pricing/PricingTable";

import SidedList from "./General/SidedList";
import Images from "./General/Images";
import CurrentVacancies from "./General/CurrentVacancies";

import DemoGroup from "./Demo/DemoGroup";
import FullScreenDemoGroup from "./Demo/FullScreenDemoGroup";

import ValidationCalculator from "./Calculators/Validation";

// import Pullquote from "./General/Pullquote";
// import OneSystem from "./Home/OneSystem";
// import Partners from "./Home/Partners";
// import SuccessCard from "../components/Success/SuccessCard";
// import ACFImage from "./General/ACFImage";
// import Listicle from "./General/Listicle";
import Video from "./General/Video";
import Columns from "./General/Columns";
// import GalleryGrid from './General/GalleryGrid'
// import HubspotForm from './General/HubspotForm'
import HomeSuccess from "./Home/HomeSuccess";

import { Fade } from "react-awesome-reveal";
import ImageModule from "./General/ImageModule";

const ModuleLayout = ({ page, blocks, bodyClass, url }) => {
  return (
    <React.Fragment>
      {blocks &&
        blocks.map(function (block, i) {
          if (!block.type) {
            return false;
          } else if (block.type === "hero") {
            return (
              <HeroIntro
                key={"block" + i}
                background={block.background}
                headerType={block.headerType}
                subtitle={block.header0}
                title2={block.header1}
                title1={block.header2}
                massiveHero={block.massiveHero}
                excerpt={block.intro}
                content={block.content}
                classes={``}
                ctaLink={block.cta_1_link}
                ctaText={block.cta_1_text}
                cta2Link={block.cta_2_link}
                cta2Text={block.cta_2_text}
                image1={block.image}
                // categories={categories}
              />
            );
          } else if (block.type === "podcast_header") {
            return (
              <PodcastHeader
                key={"block" + i}
                title1={block.header1}
                title2={block.header2}
                intro={block.intro}
                subtitle={block.header0}
                hosts={block.hosts}
                // categories={categories}
              />
            );
          } else if (block.type === "home_hero") {
            return (
              <HomeHero
                key={"block" + i}
                intro_subheader={block.header0}
                intro_hello={block.header1}
                intro_title={block.header2}
                intro_intro={block.intro}
                intro_image={block.image}
                testimonial_image={block.testimonialImage}
                testimonial_from={block.testimonialFrom}
                testimonial={block.testimonialContent}
                ctaLink={block.cta_1_link}
                ctaText={block.cta_1_text}
                cta2Link={block.cta_2_link}
                cta2Text={block.cta_2_text}
                logo_title={block.logo_title}
                logos={block.logos}
              />
            );
          } else if (block.type === "cards") {
            return (
              <Fade key={"block" + i}>
                <LearnMore
                  title={block.title}
                  cards={block.cards}
                  background={block.background}
                />
              </Fade>
            );
          } else if (block.type === "HomeSuccess") {
            return (
              <Fade key={"block" + i}>
                <HomeSuccess
                  title={block.title}
                  linked_success_story={block.linked_success_story}
                  more_link_title={block.more_link_title}
                />
              </Fade>
            );
          } else if (block.type === "stats") {
            return (
              <Fade key={"block" + i}>
                <Stats
                  stats={block.stats}
                  statsTitle={block.statsTitle}
                  statsIntro={block.statsIntro}
                />
              </Fade>
            );
          } else if (block.type === "faqs") {
            return (
              <Fade key={"block" + i}>
                <FAQBlock
                  title={block.title}
                  intro={block.intro}
                  faqs={block.faqs}
                  ctaUrl={block.cta_1_link}
                  ctaText={block.cta_1_text}
                />
              </Fade>
            );
          } else if (block.type === "features" || block.type === "benefits") {
            return (
              <Fade key={"block" + i}>
                <Features
                  title={block.benefit_title}
                  intro={block.benefit_intro}
                  features={block.benefits}
                />
              </Fade>
            );
          } else if (block.type === "logolockup") {
            return (
              <Fade key={"block" + i}>
                <LogoLockup
                  title={block.title}
                  logos={block.logos}
                  fullHeight={block.fullHeight}
                />
              </Fade>
            );
          } else if (block.type === "simple_cta") {
            return (
              <Fade key={"block" + i}>
                <SimpleCTA
                  title={block.title}
                  cta_1_link={block.cta_1_link}
                  cta_1_text={block.cta_1_text}
                  background={block.background}
                  full_width={block.full_width}
                />
              </Fade>
            );
          } else if (block.type === "testimonialcards") {
            return (
              <Fade key={"block" + i}>
                <Testimonials
                  title={block.title}
                  cards={block.cards}
                  background={block.background}
                />
              </Fade>
            );
          } else if (block.type === "large_quote") {
            return (
              <LargeQuote
                key={"block" + i}
                quote={block.testimonialContent}
                quotee={block.testimonialFrom}
                quotee_title={block.quotee_title}
                largequote_image={block.testimonialImage}
                background={block.background}
              />
            );
          } else if (block.type === "image_text") {
            return (
              <Fade key={"block" + i}>
                <ImageText
                  blockId={i}
                  background={block.background}
                  title={block.title}
                  subtitle={block.subheading}
                  content={block.content}
                  imagetext_image={block.image}
                  image_order={block.image_leftright}
                  align={block.align}
                  cta_1_text={block.cta_1_text}
                  cta_1_link={block.cta_1_link}
                  cta_1_button={block.cta_1_button}
                  hidePadding={block.hidePadding}
                  // sticky_top={block.sticky_top}
                  // negative_margin={block.negative_margin}
                  // negative_margin_double={block.negative_margin_double}
                />
              </Fade>
            );
          } else if (block.type === "pricing_table") {
            return (
              <Fade key={"block" + i}>
                <PricingTable
                  card_1_title={block.cta_1_text}
                  card_2_title={block.cta_2_text}
                  cards={block.cards}
                  something_else_title={block.header2}
                  something_else_cta_url={block.cta_3_link}
                  something_else_cta_text={block.cta_3_text}
                />
              </Fade>
            );
          } else if (block.type === "iframe") {
            return (
              <Fade key={"block" + i}>
                <IFrameEmbed
                  url={block.url}
                  include_side_content={block.include_side_content}
                  side_title={block.side_title}
                  side_content={block.side_content}
                />
              </Fade>
            );
          } else if (block.type === "validation_calculator") {
            return (
              <Fade key={"block" + i}>
                <ValidationCalculator
                  intro={block.intro}
                  title={block.title}
                  leads_title={block.leads_title}
                  volume_title={block.volume_title}
                  save_title={block.save_title}
                  button_text={block.button_text}
                  disclaimer={block.disclaimer}
                />
              </Fade>
            );
          } else if (block.type === "general_content") {
            return (
              <Fade key={"block" + i}>
                <GeneralContent
                  full_width={block.full_width}
                  content={block.body}
                  backgroundColour={block.background}
                  alignment={block.align}
                  accentColour={block.accentColour}
                  // pull_link_title={block.attrs.data.pull_link_title}
                  // pull_link_content={block.attrs.data.pull_link_content}
                  // pull_link_read_more={block.attrs.data.pull_link_read_more}
                  // blockId={i}
                />
              </Fade>
            );
          } else if (block.type === "sidedList") {
            return (
              <SidedList
                cards={block.cards}
                title={block.title}
                intro={block.intro}
                ctaLink={block.cta_1_link}
                ctaText={block.cta_1_text}
                backgroundColour={block.background}
                numbers={block.numbers}
                columns={block.columns}
                key={"block" + i}
              />
            );
          } else if (block.type === "images") {
            return (
              <Fade key={"block" + i}>
                <Images images={block.images} />
              </Fade>
            );
          } else if (block.type === "currentVacanices") {
            return (
              <Fade key={"block" + i}>
                <CurrentVacancies
                  header1={block.header1}
                  header2={block.header2}
                  intro={block.intro}
                />
              </Fade>
            );
          } else if (block.type === "newsletter") {
            return (
              <Fade key={"block" + i}>
                <Newsletter
                  title={block.title}
                  content={block.content}
                  color={block.color}
                  portalId={block.portalId}
                  formId={block.formId}
                />
              </Fade>
            );
          } else if (block.type === "image")
            return (
              <Fade key={"block" + i}>
                <ImageModule {...block} />
              </Fade>
            );
          // else if (block.type === "image")
          //   return (
          //     <Fade key={"block" + i}>
          //       <ACFImage
          //         options={block.attrs.data.options}
          //         block_image={block.attrs.data.block_image}
          //       />
          //     </Fade>
          //   );
          // else if (block.type === "listicle")
          //   return (
          //     <Fade key={"block" + i}>
          //       <Listicle
          //         listicles={block.attrs.data.listicle}
          //         full_width={block.attrs.data.full_width}
          //       />
          //     </Fade>
          //   );
          else if (block.type === "video-block")
            return (
              <Fade key={"block" + i}>
                <Video
                  options={block.options}
                  image={block.image}
                  thumbnail={block.thumbnail}
                  full_width={block.full_width}
                  video={block.video}
                  caption={block.caption}
                  controls={block.controls}
                  muted={block.thumbnail ? false : block.autoplay}
                  playing={block.autoplay}
                />
              </Fade>
            );
          else if (block.type === "demo_block")
            return (
              <Fade key={"block" + i}>
                <DemoGroup videos={block.videos} />
              </Fade>
            );
          else if (block.type === "fullscreen_demo_block")
            return (
              <Fade key={"block" + i}>
                <FullScreenDemoGroup videos={block.videos} />
              </Fade>
            );
          // else if (block.type === "pullquote")
          //   return (
          //     <Fade key={"block" + i}>
          //       <Pullquote
          //         full_width={block.attrs.data.full_width}
          //         pullquote_text={block.attrs.data.pullquote_text}
          //         pullquote_quotee={block.attrs.data.pullquote_quotee}
          //         url={url}
          //         title={page.title}
          //       />
          //     </Fade>
          //   );
          else if (block.type === "general-columns") {
            return (
              <Fade key={"block" + i}>
                <Columns
                  columns={block.columns}
                  title={block.title}
                  cards={block.cards}
                />
              </Fade>
            );
          }
          // else if (block.type === "success-card")
          //   return (
          //     <section class="container" key={"block" + i}>
          //       <div class="row my-5 py-5 justify-content-center">
          //         <div class="col-lg-6">
          //           <SuccessCard
          //             title={block.attrs.data.success_story__card.post_title}
          //             excerpt={block.attrs.data.success_story__card.excerpt}
          //             image={
          //               block.attrs.data.success_story__card.featured_image
          //             }
          //             slug={block.attrs.data.success_story__card.permalink}
          //           />
          //         </div>
          //       </div>
          //     </section>
          //   );
          else return <div>{block.type}</div>;
        })}
    </React.Fragment>
  );
};

export default ModuleLayout;
