import React from "react";
import FullScreenDemo from "./FullScreenDemo";

let playingVideo = {};

export const FullScreenDemoGroup = function ({ videos }) {
  if (videos) {
    videos.map(function (video, index) {
      playingVideo[index] = video.playing;
      return true;
    });
  }

  return (
    <React.Fragment>
      {videos &&
        videos.map(function (video, index) {
          return (
            <FullScreenDemo
              key={`demovideo-` + index}
              tooltips={video.captions}
              title={video.title}
              intro={video.intro}
              video={video.video}
              ctatext={video.cta_1_text}
              ctalink={video.cta_1_link}
              bg={`bg-` + video.background}
              rightside={video.rightside}
              playingVideo={playingVideo}
              id={index}
            />
          );
        })}
    </React.Fragment>
  );
};
export default FullScreenDemoGroup;
