import React from "react";

import Image from "../Hacks/Image";
import Content from "../Hacks/Content";

import { Link } from "gatsby";

export const ImageText = ({
  image_order,
  background,
  align,
  imagetext_image,
  title,
  subtitle,
  content,
  blockId,
  cta_1_text,
  cta_1_link,
  cta_1_button,
  hidePadding,
}) => {
  return (
    <section
      className={`imagetext-block container-fluid py-4 ${
        hidePadding ? "" : "py-lg-5"
      } ${background ? "bg-" + background : ""}`}
      id={blockId}
    >
      <div className={`row justify-content-center contained-xl py-lg-4`}>
        <div
          className={`
            imagetext-block-image col-12 col-md-5 col-lg-5 mb-3 mb-lg-0 
            ${align === "middle" ? "align-self-center" : ""}
            ${image_order === "RIGHT" ? "order-lg-2" : ""}
          `}
        >
          {imagetext_image && (
            <Image
              image={imagetext_image}
              className={`w-100 drop-shadow-sm`}
              alt=""
            />
          )}
        </div>
        <div
          className={`
            imagetext-block-text col-md-7 col-lg-5 mb-3
            ${
              align === "middle" ? "align-middle d-flex align-items-center" : ""
            }
          `}
        >
          <div>
            {title && <h2 className="mb-1">{title}</h2>}
            {subtitle && (
              <h3 className="h5 mb-3 text-secondary text-uppercase">
                {subtitle}
              </h3>
            )}
            <Content className={`wysiwyg`} content={content} />
            {cta_1_text && cta_1_link && (
              <Link
                cover={`true`}
                direction="left"
                className={
                  cta_1_button ? `btn btn-secondary` : "text-secondary"
                }
                to={cta_1_link}
              >
                {cta_1_text}
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageText;
