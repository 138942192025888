import React from "react";

export const PricingSlider = ({
  sliderChange,
  sliderValue,
  leadAmount,
  isOpened,
  monthly,
  yearly,
}) => {
  return (
    <div className="row contained-xl justify-content-center" id="cards">
      <div className="col-lg-4 mb-3">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center">
            <h2 className="h5 mb-0">
              <label htmlFor="leadsVolume">How many leads per month?</label>
            </h2>
          </div>
          <div className="col-lg-9 pt-lg-3">
            <input
              type="range"
              name="leadsVolume"
              id="leadsVolume"
              onChange={sliderChange}
              min="0"
              max="4"
              value={`${sliderValue ? sliderValue : "0"}`}
              className="custom-range"
            />
          </div>
          <div className="col-lg-3 pt-lg-3">
            <div className="bg-primary py-2 px-4 text-center  rounded font-weight-bold">
              {leadAmount.toLocaleString()}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`col-lg-12 align-self-baseline text-center ${
          sliderValue === 4 ? "d-none" : ""
        }`}
      >
        <div className="mb-4">
          <h2 className="h4">Show Prices</h2>
          <div
            className="btn-group btn-group-sm btn-group-toggle"
            data-toggle="buttons"
          >
            <label
              className={`btn btn-sm btn-outline-primary mr-1 ${
                isOpened ? "active" : ""
              }`}
            >
              <input
                type="radio"
                name="options"
                autoComplete="off"
                checked={isOpened ? true : false}
                onChange={monthly}
              />
              Monthly
            </label>
            <label
              className={`btn btn-sm btn-outline-primary ${
                isOpened ? "" : "active"
              }`}
            >
              <input
                type="radio"
                name="options"
                autoComplete="off"
                checked={isOpened ? false : true}
                onChange={yearly}
              />
              Yearly
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSlider;
