import React from "react";
import { Fade } from "react-awesome-reveal";
import Image from "../Hacks/Image";

export const LargeQuote = ({
  background,
  largequote_image,
  quote,
  quotee,
  quotee_title,
}) => {
  return (
    <section
      className={`large-quote bg-${
        background ? background : "light"
      } text-center`}
    >
      <Fade>
        <>
          {largequote_image && (
            <div className="large-quote-image floaty" data-scroll-speed-y="-10">
              <Image image={largequote_image} className="w-100" alt="" />
            </div>
          )}
          <div className={`py-5 row justify-content-center`}>
            <div
              className={`col-11 ${
                largequote_image ? `py-5 col-lg-7` : `col-lg-6 col-xl-5`
              }  align-middle d-flex align-items-center`}
            >
              <div
                className={`w-100 ${
                  largequote_image ? `py-5 my-md-5` : `py-5`
                }`}
              >
                <blockquote
                  className={` ${largequote_image ? `h3` : `h3`} font-italic`}
                  dangerouslySetInnerHTML={{ __html: quote }}
                />

                {quotee && <div className="h5 mt-3 text-secondary">{quotee}</div>}
                {quotee_title && (
                  <div className="h6 mt-3 text-secondary">{quotee_title}</div>
                )}
              </div>
            </div>
          </div>
        </>
      </Fade>
    </section>
  );
};

export default LargeQuote;
