import React from "react";
import Content from "../Hacks/Content";
import Image from "../Hacks/Image";

export const Testimonials = ({ title, cards, background }) => {
  var colmd = "col-md-" + 36 / cards.length;
  var collg = "col-lg-" + 12 / cards.length;

  return (
    <section
      className={`testimonials container-fluid py-5 ${
        background ? `bg-` + background : ""
      }`}
    >
      <div className={`row contained-xl justify-content-center`}>
        <div className={`col-md-10 col-lg-7`}>
          {title && <h2 className="text-center mb-4">{title}</h2>}
        </div>
      </div>
      <div className={`row contained-xl justify-content-center`}>
        <div className={`col-md-10`}>
          {cards && (
            <div className="row justify-content-center">
              {cards.map(function (card, i) {
                return (
                  <div className={`${colmd} ${collg} mb-4`} key={`card` + i}>
                    <div
                      className={`card bg-white ${
                        background === "white" ? "shadow" : ""
                      } rounded-sm h-100 align-content-bottom position-relative ${
                        card.logo ? "pb-5" : ""
                      }`}
                    >
                      <div className="card-body p-xl-4">
                        <div className="text-right">
                          <i className="fal fa-4x text-light mb-0 fa-quote-right"></i>
                        </div>
                        <div className="testimonial-inner">
                          {card.title && (
                            <h3 className="h4 mt-4 mb-2 text-secondary">
                              {card.title}
                            </h3>
                          )}
                          {card.content && <Content content={card.content} />}
                          {card.logo && (
                            <div className="testimonial-logo">
                              <Image
                                image={card.logo}
                                className="mt-3 testimonial-logo-img"
                                alt={card.title}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default Testimonials;
