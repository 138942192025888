import React from "react";
import { Link } from "gatsby";

export const SimpleCTA = ({
  title,
  intro,
  cta_1_link,
  cta_1_text,
  background,
  ctacolor,
  full_width,
}) => {
  return (
    <section className="simplecta container-fluid">
      <div
        className={`${
          title ? "pt-5" : ""
        } pb-5 row contained-xl justify-content-center text-center`}
      >
        <div
          className={`${
            full_width ? "col-lg-8 col-md-10" : "col-lg-6 col-md-6" 
          } ${
            background
              ? `${background} p-4 px-lg-5 py-lg-5 rounded-lg shadow-lg`
              : ""
          } `}
        >
          {title && <h2>{title}</h2>}
          <div>
            {cta_1_link && !cta_1_link.includes("mailto:") ? (
              <Link className="btn btn-success mt-3" to={cta_1_link}>
                {cta_1_text}
              </Link>
            ) : (
              <a className="btn text-white btn-success" href={cta_1_link}>
                {cta_1_text}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleCTA;
