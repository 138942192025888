import React from "react";
// import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";

// import Image from "../Hacks/Image";
import Author from "./Author";
import Content from "../Hacks/Content";

export default class PodcastHeader extends React.Component {
  render() {
    const { hosts, title1, title2, subtitle, intro } = this.props;

    return (
      <header id="hero" className="hero pt-5">
        <div className="position-relative container-fluid py-5 ">
          <div className="pb-md-5 row justify-content-center contained-xl">
            <div className="py-2 py-md-5 pb-md-5 col-11 col-lg-4 my-lg-2 align-self-center text-left">
              <Fade>
                <h1 className="display-3">
                  <span className="text-secondary">{title1}</span> {title2}
                  <span className="d-block h2">{subtitle}</span>
                </h1>
              </Fade>
              <Fade delay={400}>
                <Content className="lead" content={intro} />
              </Fade>
            </div>
            <div className="mt-md-0 col-11 col-lg-5 offset-lg-1 my-md-2 text-left pt-lg-5">
              <div className="mt-lg-5">
                <iframe
                  src="https://open.spotify.com/embed-podcast/show/3WdHP5DkccckZktUun2MmG"
                  width="100%"
                  height="232"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                  className="rounded bg-secondary"
                  title="b2c spotify"
                ></iframe>

                <div className="row mt-4 mb-5">
                  <div className="col-4">
                    <a
                      href="https://open.spotify.com/show/3WdHP5DkccckZktUun2MmG?si=0MJVbPlqTYWQprTZN9-x0g"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/spotify-podcast-badge-blk-grn-330x80.svg"
                        className="img-fluid w-100"
                        alt="Spotify Podcast"
                        height={330}
                        width={80}
                      />
                    </a>
                  </div>
                  <div className="col-4">
                    <a
                      href="https://podcasts.apple.com/gb/podcast/b2c-lead-generation/id1549523413"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/US_UK_Apple_Podcasts_Listen_Badge_RGB.svg"
                        className="img-fluid w-100"
                        alt="Apple Podcast"
                        height={330}
                        width={80}
                      />
                    </a>
                  </div>
                  <div className="col-4">
                    <a
                      href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80NzlkMjNmYy9wb2RjYXN0L3Jzcw=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/EN_Google_Podcasts_Badge.svg"
                        className="img-fluid w-100"
                        alt="Google Podcast"
                        height={300}
                        width={76}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {hosts && (
              <div className="col-11 col-lg-10">
                <Fade delay={600}>
                  <h2 className="mb-5">Meet The Hosts</h2>
                  <div className="row justify-content-center">
                    {hosts.map((host, index) => (
                      <div className="col-md-6" key={`host-` + index}>
                        <Author
                          name={host.name}
                          bio={host.text}
                          avatar={host.featuredimage}
                          linkedIn={host.linkedin}
                          job={host.title}
                        />
                      </div>
                    ))}
                  </div>
                </Fade>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}
