import React from "react";
import Image from "../Hacks/Image";

export const Author = function ({ name, bio, avatar, linkedIn, job }) {
  return (
    <div className="row mb-3 justify-content-center">
      <div className="col-5 col-md-11 col-lg-4 mb-4">
        {avatar && (
          <Image image={avatar} className="w-100 img-fluid" alt={name} />
        )}
      </div>
      <div className="col-7 align-content-center d-md-none col-lg-4 mb-4">
        <div className="mb-2">
          <a
            target="_blank"
            rel="noreferrer"
            href={linkedIn}
          >
            <i className="fab fa-2x mr-2 fa-linkedin"></i>
          </a>
        </div>
        <h3 className="text-md-left mb-2">
          {name}
          <div className="small">{job}</div>
        </h3>
      </div>
      <div className="col-12 col-md-11 col-lg-8 mb-4 align-self-center pr-lg-5">
        <address className="author">
          <div className="mb-2 d-none d-md-block ">
            <a
              target="_blank"
              rel="noreferrer"
              href={linkedIn}

            >
              <i className="fab fa-2x mr-2 fa-linkedin"></i>
            </a>
          </div>
          <h3 className="d-none d-md-block text-md-left mb-2">
            {name}
            <div className="small">{job}</div>
          </h3>
          <p>{bio}</p>
        </address>
      </div>
    </div>
  );
};
export default Author;
