import React from "react";
import Content from "../Hacks/Content";

export const PricingCard = ({ color, ii, feature, bold }) => {
  return (
    <li className="mt-2" key={`feature-${ii}`}>
      <span className="fa-li pr-2">
        <i className={`fas fa-check-circle small text-${color}`}></i>
      </span>
      <Content
        $tag="span"
        className={bold && "font-weight-bold"}
        content={feature}
      />
    </li>
  );
};

export default PricingCard;
