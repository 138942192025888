import React, { useState } from "react";

export const FullScreenDemoTooltip = function ({
  id,
  arrow,
  left,
  top,
  content,
  index,
  prevStart,
  next,
  setPlaying,
  videoProgress,
}) {
  const [clicked, setClicked] = useState(0);

  return (
    <div
      className={`video-tooltip video-tooltip-larger video-tooltip-vw arrow-${arrow} ${
        clicked ? "d-none" : ""
      }`}
      role="tooltip"
      style={{ left: left, top: top }}
    >
      <div className="video-tooltip-inner">
        <div dangerouslySetInnerHTML={{ __html: content }} />

        <div className="row mt-3">
          <div className="col-6">
            {index > 0 && (
              <button
                className="btn btn-white btn-sm"
                onClick={function () {
                  setPlaying(true);
                  videoProgress(id, prevStart);
                }}
              >
                Prev
              </button>
            )}
          </div>
          <div className="col-6 text-right">
            {next && (
              <button
                className={`btn btn-white btn-sm`}
                onClick={function () {
                  setPlaying(true);
                  setClicked(true);
                }}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FullScreenDemoTooltip;
