// import React, { useState, useRef } from "react";
import React from "react";
import Content from "../Hacks/Content";
// import IframeResizer from "iframe-resizer-react";
import { Widget } from "@typeform/embed-react";

export const IFrameEmbed = ({
  include_side_content,
  side_title,
  side_content,
  url,
}) => {
  //   const iframeRef = useRef(null);
  //   const [messageData, setMessageData] = useState();

  //   const onResized = (data) => setMessageData(data);

  //   const onMessage = (data) => {
  //     setMessageData(data);
  //     iframeRef.current.sendMessage("Hello back from the parent page");
  //   };

  return (
    <section className="typeform container-fluid py-lx-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="row justify-content-center">
            {include_side_content && (
              <div className="col-lg-3 col-xl-5 mb-5">
                <h2 className="mb-3">{side_title}</h2>
                <Content className="wysiwyg" content={side_content} />
              </div>
            )}
            <div className={`col-lg-10 col-xl-8`}>
              <div className="form pt-5">
                {url.includes("typeform") ? (
                  <Widget
                    id={url.replace("https://databowl.typeform.com/to/", "")}
                    style={{
                      width: "1px",
                      minWidth: "100%",
                      height: "100vh",
                      maxHeight: "950px",
                      border: 0,
                      transition: "0.3s ease all",
                      overflow: "hidden",
                    }}
                    className="my-form"
                  />
                ) : (
                  <iframe
                    // forwardRef={iframeRef}
                    // heightCalculationMethod="lowestElement"
                    // inPageLinks
                    // onMessage={onMessage}
                    // onResized={onResized}
                    src={url}
                    title={side_title}
                    style={{
                      width: "1px",
                      minWidth: "100%",
                      height: "100vh",
                      maxHeight: "950px",
                      border: 0,
                      transition: "0.3s ease all",
                      overflow: "hidden",
                    }}
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IFrameEmbed;
