import React from "react";
import { Fade } from "react-awesome-reveal";
import { Link, StaticQuery, graphql } from "gatsby";

export const CurrentVacancies = ({ intro, header1, header2 }) => {
  return (
    <section
      id="vacancies"
      className="postlists container-fluid bg-light pb-0 pt-lg-5 pb-5"
    >
      <div className="row contained-xl">
        <div className="col-md-5 offset-lg-1">
          <Fade>
            <h2 className="display-lg-1 mb-lg-4">
              {header1} <span className="text-secondary">{header2}</span>
            </h2>
            <p className="lead">{intro}</p>
          </Fade>
        </div>

        <div className="col-md-10 offset-lg-1">
          <div className="row contained-xl mt-5 d-none d-lg-block">
            <div className="col-12 mb-4">
              <Fade>
                <div className="card h-100 bg-primary">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 pb-4 pb-lg-0 col-lg-4">
                        <h2 className="card-title h5 mb-0">Role</h2>
                      </div>
                      <div className="col-12 col-lg-2">
                        <div className="font-weight-bold">Salary</div>
                      </div>
                      <div className="col-12 col-lg-2">
                        <div className="font-weight-bold">Type</div>
                      </div>
                      <div className="col-8 col-lg-2">
                        <div className="font-weight-bold">Location</div>
                      </div>
                      <div className="font-weight-bold col-4 col-lg-2 text-right">
                        Apply
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <StaticQuery
            query={graphql`
              query {
                allMarkdownRemark(
                  filter: { fields: { slug: { regex: "//vacancies/(.)/" } } }
                  sort: { order: DESC, fields: id }
                ) {
                  edges {
                    node {
                      frontmatter {
                        title
                        date
                        excerpt
                        metaDescription
                        type
                        location
                        salaryUpTo
                      }
                      fields {
                        slug
                      }
                    }
                  }
                }
              }
            `}
            render={(data) => (
              <div className="row pb-lg-5 mt-4 mt-lg-0 contained-xl">
                {data.allMarkdownRemark.edges &&
                  data.allMarkdownRemark.edges.map(
                    ({ node: vacancy }, index) => (
                      <div className="col-12 mb-4" key={`card_` + index}>
                        <Fade>
                          <div className="card h-100">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-12 pb-4 pb-lg-0 col-lg-4">
                                  <Link
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="stretched-link"
                                    to={vacancy.fields.slug}
                                  >
                                    <h2 className="card-title h5 mb-0 pt-1 text-primary">
                                      {vacancy.frontmatter.title}
                                    </h2>
                                  </Link>
                                </div>
                                <div className="col-12 col-lg-2">
                                  <div className="pt-1">
                                    {vacancy.frontmatter.salaryUpTo}
                                  </div>
                                </div>
                                <div className="col-12 col-lg-2">
                                  <div className="pt-1">
                                    {vacancy.frontmatter.type}
                                  </div>
                                </div>
                                <div className="col-8 col-lg-2">
                                  <div className="pt-1">
                                    {vacancy.frontmatter.location}
                                  </div>
                                </div>
                                <div className="col-4 col-lg-2 text-right">
                                  <Link
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="btn d-block d-lg-inline-block px-4 py-1 btn-sm btn-outline-primary stretched-link"
                                    to={vacancy.fields.slug}
                                  >
                                    Apply
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    )
                  )}
              </div>
            )}
          />
        </div>
      </div>
    </section>
  );
};

export default CurrentVacancies;
