import React, { useState } from 'react'
import { Link } from 'gatsby'

export const ValidationCalculator = ({ 
  intro,
  title,
  leads_title,
  volume_title,
  save_title,
  button_text,
  disclaimer,

 }) => {
  const [cost, setCost] = useState(1.66)
  const [volume, setVolume] = useState(10000)

  return (
    <section
      id="validation"
      className="container-fluid mt-5"
    >
      <div className="row justify-content-center ">
        <div className="col-md-8 col-lg-5 mb-5 z-index-100">
          <div
            className="bg-primary rounded shadow-lg p-md-5 py-5 px-3 text-center"
            data-aos="fade-up"
          >
            <h2>
              <div className="text-secondary mb-0 h5">{intro}</div>
              <div className="h2">{title}</div>
            </h2>
            <form className="pt-3">
              <div className="form-group" >
                <label className="h6">{leads_title}</label>
                <div className="input-group w-60 mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-secondary border-primary rounded-left px-4 font-weight-bold ">
                      &pound;
                    </span>
                  </div>
                  <input
                    type="number"
                    placeholder={leads_title}
                    className="form-control input-lg rounded-right border border-primary border-thick text-center input-currency-prepend"
                    aria-label={leads_title}
                    onChange={e => setCost(e.target.value)}
                    value={cost}
                  />
                </div>
              </div>
              <div className="form-group" >
                <label className="h6">
                {volume_title}
                </label>
                <input
                  type="Number"
                  className="w-60 rounded border border-primary border-thick text-center form-control input-lg"
                  placeholder={volume_title}
                  aria-label={volume_title}
                  onChange={e => setVolume(e.target.value)}
                  value={volume}
                />
              </div>
              {cost && volume && (
                <div className="mt-5" >
                  <React.Fragment>
                    <span className="h3">{save_title}</span>
                    <span className="h5 text-white">*</span>
                    <div className="h3 mt-3">
                      <span className="bg-warning py-1 px-4">
                        &pound; {  parseFloat(parseFloat(((((cost * volume) / 100) * 33))).toFixed(2)).toLocaleString()  }
                      </span>
                    </div>
                  </React.Fragment>
                  <Link
                    to="/contact/"
                    className="btn btn-success btn-lg text-primary mt-5"
                  >
                    {button_text}
                  </Link>
                  <p className="mt-5 small">
                    * {disclaimer}
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValidationCalculator
