import React from 'react'
import showdown from "showdown";
const converter = new showdown.Converter();



export const ContentBlock = ({ $tag = 'div', className, content }) => {
  if (content) {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: converter.makeHtml(content)
            .replace(/<\/noscript><\/div>/g, '</noscript></div></div>')
            .replace(/http:\/\/databowlcms.jamesdodd.net/g, '')
          // .replace(/\n([ \t]*\n)+/g, '</p><p>')
          // .replace(/\n/g, '<br />') + '</p>',
        }}
      />
    )
  } else {
    return false
  }
}

export default ContentBlock
