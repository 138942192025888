import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";

import { Link } from "gatsby";

let player = {};

export const Demo = function ({
  video,
  tooltips,
  title,
  intro,
  ctatext,
  ctalink,
  bg,
  rightside,
  playingVideo,
  id,
}) {
  const [played, setPlayed] = useState(0);
  const [playing, setPlaying] = useState(playingVideo[id]);

  let videoProgress = function (playing, seek) {
    //set the video as playing
    startPlaying(true);

    //set the track head
    return playing === id ? player[id].seekTo(seek) : false;
  };

  let startPlaying = function (playId) {
    //set the video as playing
    setPlaying(true);

    //pause other videos
    for (const vidId in playingVideo) {
      playingVideo[vidId] = false;
    }
    playingVideo[playId] = playing;
    console.log(playId, playingVideo[playId], playing);
  };

  return (
    <section
      id={`video-` + id}
      className={`postlists container-fluid py-5 ${bg}`}
    >
      <div className="row contained-xl justify-content-center mt-lg-5 pb-5">
        <div className={`col-11 text-center my-5 d-lg-none`}>
          <header>
            <h1 className="display-3 mb-3">{title}</h1>
            <div className="lead mb-1">{intro}</div>
          </header>
        </div>
        <div
          className={`${
            rightside ? "order-md-1 offset-xl-1" : "order-md-0"
          } order-2 col-7 col-md-3 col-xl-3 mb-4`}
        >
          <header className="d-none d-lg-block">
            <h2>{title}</h2>
            <div className="lead mb-1">{intro}</div>
          </header>
          <nav className="d-none d-md-block">
            {tooltips?.map(function (tooltip, index) {
              return (
                <React.Fragment key={`video-chapter-${index}`}>
                  {tooltip.name && (
                    <div
                      className={`video-chapter ${
                        played >= tooltip.start && played <= tooltip.end
                          ? "playing"
                          : played >= tooltip.start
                          ? "played"
                          : "queued"
                      }`}
                    >
                      {tooltips.length !== index + 1 && (
                        <span
                          className="progress"
                          style={{
                            height: `${
                              played >= tooltip.end
                                ? 100
                                : played >= tooltip.start &&
                                  played <= tooltip.end
                                ? ((played - tooltip.start) /
                                    (tooltip.end - tooltip.start)) *
                                  100
                                : 0
                            }%`,
                          }}
                        ></span>
                      )}
                      <button
                        className="btn-link btn p-0 text-left"
                        onClick={function () {
                          videoProgress(id, tooltip.start);
                          playingVideo[id] = true;
                        }}
                        onKeyDown={function () {
                          videoProgress(id, tooltip.start);
                          playingVideo[id] = true;
                        }}
                      >
                        {tooltip.name}
                      </button>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </nav>
          <footer className="text-center text-md-left">
            {!playingVideo[id] ? (
              <button
                className={`btn btn-${
                  bg !== "bg-white" && bg !== "bg-light" ? "white" : "primary"
                } btn-sm mt-4 mr-2`}
                onClick={function () {
                  startPlaying(id);
                  playingVideo[id] = true;
                }}
              >
                <i className="fa fa-play mr-2"></i> Play
              </button>
            ) : (
              <button
                className={`btn btn-${
                  bg !== "bg-white" && bg !== "bg-light" ? "white" : "primary"
                } btn-sm mt-4 mr-2`}
                onClick={function () {
                  setPlaying(false);
                  playingVideo[id] = false;
                }}
              >
                <i className="fa fa-pause mr-2"></i> Pause
              </button>
            )}

            {ctatext && (
              <Link className="btn btn-secondary btn-sm mt-4" to={ctalink}>
                {ctatext}
              </Link>
            )}
          </footer>
        </div>

        <div
          className={`${
            rightside ? "order-0" : "order-1 offset-xl-1"
          } mb-5 col-md-8 col-lg-8 col-xl-6 align-self-center position-relative`}
        >
          <div className="demo-video-embed">
            {video && (
              <ReactPlayer
                ref={(ref) => {
                  player[id] = ref;
                }}
                url={video}
                // vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
                className={`react-player demo-video-embed-item ${bg}`}
                width="100%"
                height="100%"
                volume={0}
                playing={playingVideo[id]}
                controls={true}
                playsinline={true}
                playIcon={<i class="far fa-play-circle text-secondary h1 "></i>}
                muted={true}
                light={false}
                loop={true}
                onClickPreview={() => {
                  startPlaying(id);
                  // console.log("playing " + id);
                  playingVideo[id] = true;
                }}
                onPlay={() => {
                  startPlaying(id);
                  // console.log("playing " + id);
                  playingVideo[id] = true;
                }}
                onPause={() => {
                  // console.log("pause " + id);
                  playingVideo[id] = false;
                }}
                onProgress={(progress) => {
                  // console.log("paying " + id);
                  setPlayed(Math.round(progress.playedSeconds));
                }}
              />
            )}
          </div>

          {tooltips?.map(function (tooltip, index) {
            return (
              <React.Fragment key={`video-tooltip-` + index}>
                {tooltip.content &&
                  played >= tooltip.start &&
                  played <= tooltip.end && (
                    <div
                      className={`video-tooltip arrow-${tooltip.arrow}`}
                      role="tooltip"
                      style={{ left: tooltip.left, top: tooltip.top }}
                    >
                      <div className="video-tooltip-inner">
                        {tooltip.content}
                      </div>
                    </div>
                  )}
              </React.Fragment>
            );
          })}

          <nav className="d-md-none video-chapters">
            {tooltips?.map(function (tooltip, index) {
              return (
                <React.Fragment key={`video-chapter-mobile-${index}`}>
                  {tooltip.name && (
                    <div
                      className={`video-chapter ${
                        played >= tooltip.start && played <= tooltip.end
                          ? "playing"
                          : played >= tooltip.start
                          ? "played"
                          : "queued"
                      }`}
                    >
                      {tooltips.length !== index + 1 && (
                        <span
                          className="progress"
                          style={{
                            height: `${
                              played >= tooltip.end
                                ? 100
                                : played >= tooltip.start &&
                                  played <= tooltip.end
                                ? ((played - tooltip.start) /
                                    (tooltip.end - tooltip.start)) *
                                  100
                                : 0
                            }%`,
                          }}
                        ></span>
                      )}
                      <button
                        className="btn-link btn p-0 text-left"
                        onClick={function () {
                          videoProgress(id, tooltip.start);
                          playingVideo[id] = true;
                        }}
                        onKeyDown={function () {
                          videoProgress(id, tooltip.start);
                          playingVideo[id] = true;
                        }}
                      >
                        <span>{tooltip.name}</span>
                      </button>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </nav>
        </div>
      </div>
    </section>
  );
};
export default Demo;
