import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import FullScreenDemoTooltip from "./FullScreenDemoTooltip";

let player = {};

export const FullScreen = function ({ video, tooltips, bg, playingVideo, id }) {
  const [played, setPlayed] = useState(0);
  const [playing, setPlaying] = useState(true);

  let videoProgress = function (playing, seek) {
    //set the video as playing
    startPlaying(true);

    //set the track head
    return playing === id ? player[id].seekTo(seek) : false;
  };

  let startPlaying = function (playId) {
    //set the video as playing
    setPlaying(true);

    //pause other videos
    for (const vidId in playingVideo) {
      playingVideo[vidId] = false;
    }
    playingVideo[playId] = playing;
  };

  useEffect(() => {
    if (tooltips.findIndex((t) => parseInt(t.start) === played) !== -1) {
      setPlaying(false);
      playingVideo[id] = false;
    }
  }, [tooltips, setPlaying, played, id, playingVideo]);

  return (
    <section
      id={`video-` + id}
      className={`postlists container-fluid px-0 ${bg}`}
    >
      <div className="row justify-content-center">
        <div className={`col-lg-12 align-self-center position-relative`}>
          <div className="demo-video-embed demo-video-embed-vw">
            {video && (
              <ReactPlayer
                ref={(ref) => {
                  player[id] = ref;
                }}
                url={video}
                // vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
                className={`react-player demo-video-embed-item ${bg}`}
                width="100%"
                height="100%"
                volume={0}
                playing={playing}
                controls={false}
                playsinline={true}
                playIcon={<i class="far fa-play-circle text-secondary h1 "></i>}
                muted={true}
                light={false}
                loop={true}
                onClickPreview={() => {
                  startPlaying(id);
                  // console.log("playing " + id);
                  playingVideo[id] = true;
                }}
                onPlay={() => {
                  startPlaying(id);
                  // console.log("playing " + id);
                  playingVideo[id] = true;
                }}
                onPause={() => {
                  // console.log("pause " + id);
                  playingVideo[id] = false;
                }}
                onProgress={(progress) => {
                  // console.log("paying " + id);
                  setPlayed(Math.round(progress.playedSeconds));
                }}
              />
            )}
          </div>

          {tooltips?.map(function (tooltip, index) {
            return (
              <React.Fragment key={`video-tooltip-` + index}>
                {tooltip.content &&
                  played >= tooltip.start &&
                  played <= tooltip.end && (
                    <FullScreenDemoTooltip
                      id={id}
                      index={index}
                      {...tooltip}
                      setPlaying={setPlaying}
                      videoProgress={videoProgress}
                      next={index < tooltips.length}
                      prevStart={index > 0 ? tooltips[index - 1].start : false}
                    />
                  )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default FullScreen;
